import FirmaDigital from './FirmaDigital'


class FirmaDigitalConfirm extends FirmaDigital {
  constructor(props) {
    super(props)
    this.state={
        ...this.state,
        banderaConfirmation: true,
        legales: [],
        confirmation: '',
        code: '',
    }
  }
}

export default FirmaDigitalConfirm;